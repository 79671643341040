import { Styles } from 'common/lib/material-ui/theme';

export const screenContainer: Styles = {
  display: 'flex',
  height: { desktop: '100vh' },
  width: { desktop: '100vw' },
  background: 'linear-gradient(117.91deg, #E1F6FF 10.2%, #FFF7E4 75.88%);',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'relative',
};

export const appContainer: Styles = {
  display: 'flex',
  position: 'relative',
  borderRadius: { desktop: 5 },
  bgcolor: { mobile: '#FFF', dekstop: '#FFFB' },
  boxShadow: '0px 50px 60px -20px rgba(0, 5, 75, 0.14)',
  width: '100%',
  maxWidth: '780px',
  height: { mobile: '100%', desktop: '95%' },
  maxHeight: { desktop: '897px' },
};

export const contentContainer: Styles = {
  zIndex: 2,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backdropFilter: 'blur(15px)',
  borderRadius: { desktop: 5 },
};

export const title: Styles = {
  visibility: 'hidden',
  height: 0,
};
