import { FC } from 'react';
import { Button, CircularProgress, Box, Typography } from '@mui/material';

import { AppMessages } from 'locale/i18n/messages';
import { useLocale } from 'locale/useLocale/useLocale';
import { useFeedbackContextApi } from 'app/FeedbackForm/hooks/useFeedbackContextApi';
import * as styles from '../Response.styles';

export const ErrorResponse: FC = () => {
  const { formatMessage } = useLocale();
  const { submitForm, isLoading } = useFeedbackContextApi();

  return (
    <Box sx={styles.feedbackContainer}>
      <Typography sx={styles.emojiBig}>😬</Typography>
      <Typography sx={styles.feedbackMessage}>{formatMessage({ id: AppMessages['error.message'] })}</Typography>
      <Box sx={styles.container}>
        <Button onClick={() => submitForm()} variant="outlined" color="primary">
          {isLoading ? (
            <CircularProgress title="loading" size="32px" />
          ) : (
            formatMessage({ id: AppMessages['error.tryAgain'] })
          )}
        </Button>
      </Box>
    </Box>
  );
};
