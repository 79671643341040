import { FC, useState } from 'react';
import { useEffectOnce, useInterval } from 'usehooks-ts';
import { Box } from '@mui/material';

import * as styles from './Rogals.styles';

const randomize = (v: number, factor: number) => v * (1 - factor + Math.random() * factor * 2);
const transformRotate = (deg: number) => `rotate(${deg}deg)`;
const getRotateDeg = (property: string) => parseInt(property.match(/\d+/g)?.[0] || '360');

const initialRogalGornyState = {
  top: -140,
  right: -150,
  transform: transformRotate(360),
};

const initialRogalDolnyState = {
  left: -80,
  bottom: -90,
  transform: transformRotate(360),
};

export const Rogals: FC = () => {
  const [rogalGorny, setRogalGorny] = useState<{
    right: number;
    top: number;
    transform: string;
  }>(initialRogalGornyState);
  const [rogalDolny, setRogalDolny] = useState<{
    left: number;
    bottom: number;
    transform: string;
  }>(initialRogalDolnyState);

  const setRogalsPosition = () => {
    setRogalGorny({
      top: randomize(initialRogalGornyState.top, 0.25),
      right: randomize(initialRogalGornyState.right, 0.25),
      transform: transformRotate(randomize(getRotateDeg(initialRogalGornyState.transform), 0.1)),
    });
    setRogalDolny({
      bottom: randomize(initialRogalDolnyState.bottom, 0.25),
      left: randomize(initialRogalDolnyState.left, 0.25),
      transform: transformRotate(randomize(getRotateDeg(initialRogalDolnyState.transform), 0.1)),
    });
  };

  useEffectOnce(() => {
    setRogalsPosition();
  });

  useInterval(() => {
    setRogalsPosition();
  }, 5000);

  return (
    <>
      <Box sx={styles.rogal} style={rogalGorny}>
        <img src="./assets/svg/rogalGorny.svg" alt="rogal-gorny" />
      </Box>
      <Box sx={styles.rogal} style={rogalDolny}>
        <img src="./assets/svg/rogalDolny.svg" alt="rogal-dolny" />
      </Box>
    </>
  );
};
