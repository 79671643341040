import { Styles } from 'common/lib/material-ui/theme';

export const feedbackContainer: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: { mobile: '100vh', desktop: '100%' },
  px: { mobile: 6.25, desktop: 22 },
};

export const emojiBig: Styles = {
  fontSize: 80,
  lineHeight: 0.96,
};

export const feedbackMessage: Styles = {
  fontSize: { mobile: 18, desktop: 24 },
  fontWeight: 700,
  textAlign: 'center',
  mt: 2.5,
};

export const container: Styles = {
  display: 'flex',
  my: 5,
  justifyContent: 'center',
};
