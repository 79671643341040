import { Box } from '@mui/material';

import { useIsMobile } from 'common/lib/material-ui/useIsMobile';
import { useFeedbackContextForm } from 'app/FeedbackForm/hooks/useFeedbackContextForm';

import * as styles from './Logo.styles';

export const Logo = () => {
  const isMobile = useIsMobile();
  const { isAnyRatePicked } = useFeedbackContextForm();

  return (
    <Box sx={styles.logoContainer(isAnyRatePicked)}>
      <Box
        component="img"
        src="./assets/svg/TSHBig.svg"
        alt="TSH logo"
        sx={styles.logoImage(isMobile || isAnyRatePicked)}
      />
    </Box>
  );
};
