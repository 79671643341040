import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { AppMessages } from 'locale/i18n/messages';
import { useLocale } from 'locale/useLocale/useLocale';
import * as styles from '../Response.styles';

export const SuccessResponse: FC = () => {
  const { formatMessage } = useLocale();

  return (
    <Box sx={styles.feedbackContainer}>
      <img src="./assets/svg/manOnChair.svg" alt={formatMessage({ id: AppMessages['success.success'] })} />
      <Typography sx={styles.feedbackMessage}>{formatMessage({ id: AppMessages['success.message'] })}</Typography>
    </Box>
  );
};
