import { Styles } from 'common/lib/material-ui/theme';

type TextStyleProps = (isBig: boolean) => Styles;

export const text: TextStyleProps = (isBig) => ({
  fontWeight: 700,
  lineHeight: 1.4,
  textAlign: 'center',
  mt: {
    mobile: isBig ? 5 : 2.25,
    desktop: isBig ? 4 : 5.25,
  },
  fontSize: {
    mobile: 18,
    desktop: isBig ? 24 : 18,
  },
});
