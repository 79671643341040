import { Styles } from 'common/lib/material-ui/theme';

export const text: Styles = {
  fontSize: { mobile: 14, desktop: 16 },
  mt: { mobile: 1, desktop: 5.5 },
  lineHeight: 1.7,
  textAlign: { mobile: 'center', desktop: 'start' },
  whiteSpace: 'pre-line',
  letterSpacing: { mobile: 0, desktop: 0.2 },
  px: { desktop: 2 },
};

export const optionalText: Styles = {
  fontSize: { mobile: 14, desktop: 16 },
  textAlign: 'center',
  color: 'optional.main',
};
