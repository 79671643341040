import { Typography } from '@mui/material';

import * as styles from './Counter.styles';
import { CounterProps } from './Counter.types';

export const Counter = ({ count, limit }: CounterProps) => {
  return (
    <Typography sx={styles.counter} data-testid="counter">
      {count}
      {limit && `/${limit}`}
    </Typography>
  );
};
