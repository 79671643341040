import './App.css';

import { FC } from 'react';

import { FeedbackForm } from '../FeedbackForm/FeedbackForm';

import { AppContextProvider } from './context/AppContext';

const AppFC: FC = () => {
  return <FeedbackForm />;
};

export const App = () => (
  <AppContextProvider>
    <AppFC />
  </AppContextProvider>
);
