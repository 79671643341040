import { Styles } from 'common/lib/material-ui/theme';

export const container: Styles = {
  display: 'flex',
  my: 5,
  justifyContent: 'center',
};

export const button: Styles = {
  width: { desktop: '250px' },
};

export const loader: Styles = {
  color: 'mono.white',
};
