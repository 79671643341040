import { Styles } from 'common/lib/material-ui/theme';

export const container: Styles = {
  display: 'flex',
  justifyContent: 'center',
  mt: 1.5,
};

export const button: Styles = {
  color: 'primary.main',
  textTransform: 'unset',
  fontSize: 18,
  fontWeight: 500,
  letterSpacing: 0.1,
};
