import { useContext } from 'react';

import { FeedbackFormContext } from '../context/FeedbackFormContext';

export const useFeedbackContextAppState = () => {
  const context = useContext(FeedbackFormContext);
  if (context === undefined)
    throw new Error('useFeedbackContextAppState must be used within a FeedbackFormContextProvider');

  const { appState } = context;

  return {
    appState,
  };
};
