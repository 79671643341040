import { FC } from 'react';
import { Button, ButtonProps, CircularProgress, Box } from '@mui/material';

import { AppMessages } from 'locale/i18n/messages';
import { useLocale } from 'locale/useLocale/useLocale';

import * as styles from './SubmitButton.styles';

export const SubmitButton: FC<ButtonProps & { isLoading: boolean }> = ({ isLoading, ...buttonProps }) => {
  const { formatMessage } = useLocale();

  return (
    <Box sx={styles.container}>
      <Button color="primary" variant="contained" sx={styles.button} {...buttonProps}>
        {isLoading ? (
          <CircularProgress title="loading" size="32px" sx={styles.loader} />
        ) : (
          formatMessage({ id: AppMessages['rating.submit'] })
        )}
      </Button>
    </Box>
  );
};
