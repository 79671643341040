import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { AppMessages } from 'locale/i18n/messages';
import { useLocale } from 'locale/useLocale/useLocale';

import * as styles from './RatingHelpers.styles';

export const RatingHelpers: FC = () => {
  const { formatMessage } = useLocale();

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.helper}>{formatMessage({ id: AppMessages['rating.helper.0'] })}</Typography>
      <Typography sx={styles.helper}>{formatMessage({ id: AppMessages['rating.helper.10'] })}</Typography>
    </Box>
  );
};
