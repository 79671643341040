import { FC } from 'react';
import { Typography } from '@mui/material';

import { AppMessages } from 'locale/i18n/messages';
import { useLocale } from 'locale/useLocale/useLocale';
import { rateToText } from 'modules/rate/Rate';
import { Rate } from 'modules/rate/Rate.types';

import * as styles from './SummaryText.styles';

export const SummaryText: FC<{ rate: Rate }> = ({ rate }) => {
  const { formatMessage } = useLocale();

  return (
    <Typography data-testid="summary-text" sx={styles.text}>
      {formatMessage({ id: AppMessages[rateToText(rate)] })}{' '}
      <Typography component="span" sx={styles.optionalText}>
        (
        {formatMessage({
          id: AppMessages['rating.feedback.optionalText'],
        })}
        )
      </Typography>
    </Typography>
  );
};
