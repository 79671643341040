import { Styles } from 'common/lib/material-ui/theme';

type LogoContainerStylesProps = (hasFixedMt: boolean) => Styles;

export const logoContainer: LogoContainerStylesProps = (hasFixedMt) => ({
  display: 'flex',
  justifyContent: 'center',
  transition: 'margin-top 0.15s ease-out',

  mt: (theme) => ({
    mobile: 7.5,
    desktop: hasFixedMt ? 10 : `clamp(${theme.spacing(6.25)}, 6.5vh, ${theme.spacing(10)})`,
  }),
});

type LogoImageStylesProps = (hasFixedHeight: boolean) => Styles;

export const logoImage: LogoImageStylesProps = (hasFixedHeight) => ({
  height: hasFixedHeight ? 50 : 'clamp(50px, 6.5vh, 75px)',
  transition: 'height 0.15s ease-out',
});
