import { Styles } from 'common/lib/material-ui/theme';

export const helper: Styles = {
  fontSize: { mobile: 14, desktop: 16 },
  color: 'text.secondary',
  fontWeight: 500,
};

export const container: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: { mobile: 'row-reverse', desktop: 'row' },
  mt: { mobile: 4, desktop: 0 },
};
