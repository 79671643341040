import { Box, Typography } from '@mui/material';

import { AppMessages } from 'locale/i18n/messages';
import { useLocale } from 'locale/useLocale/useLocale';
import * as styles from '../Response.styles';

export const AlreadySentResponse = () => {
  const { formatMessage } = useLocale();

  return (
    <Box sx={styles.feedbackContainer}>
      <Typography sx={styles.emojiBig}>😃</Typography>
      <Typography sx={styles.feedbackMessage}>{formatMessage({ id: AppMessages['success.alreadySent'] })}</Typography>
    </Box>
  );
};
