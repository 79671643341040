import { Styles } from 'common/lib/material-ui/theme';

export const recipientsContainer: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

export const heardText: Styles = {
  mt: { mobile: 5, desktop: 4 },
  fontSize: 24,
  lineHeight: 1,
  fontWeight: 700,
};

export const reviewText: Styles = {
  mt: 1,
  fontSize: { mobile: 14, desktop: 16 },
  fontWeight: 500,
  color: 'text.secondary',
};

export const cardsContainer: Styles = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  my: { mobile: 5.25, desktop: 2.5 },
  gap: { mobile: 1.875, desktop: 2 },
  width: '100%',
};
