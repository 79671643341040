import { FC } from 'react';
import { Button, Box } from '@mui/material';

import { LambdaFn } from 'common/common.types';
import { useIsMobile } from 'common/lib/material-ui/useIsMobile';
import {
  RATES_LIST,
  rateToButtonBgColor,
  rateToButtonBorderColor,
  rateToPickedBgColor,
  ratingButtonBackgroundColor,
  ratingButtonFontColor,
} from 'modules/rate/Rate';
import { Rate } from 'modules/rate/Rate.types';

import * as styles from './RateButtonsList.styles';

export const RateButtonsList: FC<{
  value: Rate | undefined;
  onChange: LambdaFn<Rate, void>;
}> = ({ onChange, value }) => {
  // TODO: i believe it can be done without useIsMobile
  const isMobile = useIsMobile();

  return (
    <Box sx={styles.container} data-testid="rate-buttons-list">
      {RATES_LIST.map((rate) => {
        const isPicked = value === rate;
        const isAnyPicked = value !== undefined;

        return (
          <Button
            key={rate}
            onClick={() => onChange(rate)}
            variant="contained"
            sx={{
              ...styles.button,
              color: ratingButtonFontColor(isMobile, isPicked),
              bgcolor: ratingButtonBackgroundColor(isAnyPicked, isPicked, isMobile, rate),
              borderColor: isPicked ? rateToPickedBgColor(rate) : rateToButtonBorderColor(rate),
              ':hover': {
                color: 'mono.white',
                borderColor: rateToPickedBgColor(rate),
                bgcolor: !isAnyPicked || isPicked ? rateToPickedBgColor(rate) : rateToButtonBgColor(rate),
              },
            }}
          >
            {rate}
            {!isMobile && isPicked && <img src="./assets/svg/checkmark.svg" alt="checkmark" />}
          </Button>
        );
      })}
    </Box>
  );
};
