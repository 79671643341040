import { useContext } from 'react';

import { FeedbackFormContext } from '../context/FeedbackFormContext';

export const useFeedbackContextForm = () => {
  const context = useContext(FeedbackFormContext);
  if (context === undefined)
    throw new Error('useFeedbackContextForm must be used within a FeedbackFormContextProvider');

  const { submitForm, isAnyRatePicked, setValue, rateWatch, opinionWatch, reset, register } = context;

  return {
    submitForm,
    isAnyRatePicked,
    setValue,
    rateWatch,
    opinionWatch,
    reset,
    register,
  };
};
