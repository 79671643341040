import { Styles } from 'common/lib/material-ui/theme';

export const container: Styles = {
  display: 'flex',
  mt: { mobile: 1.5, desktop: 0 },
  mb: { mobile: 2.5, desktop: 2 },
  gap: 1.5,
  flexDirection: { mobile: 'row', desktop: 'row-reverse' },
  justifyContent: { desktop: 'space-between' },
};

export const button: Styles = {
  fontSize: 20,
  fontWeight: 700,
  width: { mobile: 70, desktop: 48 },
  height: { mobile: 70, desktop: 60 },
  borderRadius: 2,
  border: 1,
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  lineHeight: 1.2,
};
