import axios from 'axios';
import { createContext, useMemo, useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { zodResolver } from '@hookform/resolvers/zod';

import { useSentList } from 'app/FeedbackForm/hooks/useSentList';
import { useUrl } from 'common/hooks/useUrl';
import { PostBody, PostResponseError, PostResponseSuccess } from 'modules/form/api';
import { formSchema, FormSchema } from 'modules/form/formSchema';
import { useAppState } from 'app/FeedbackForm/hooks/useAppState';

import { FeedbackFormContextValue, FeedbackFormProps } from './FeedbackFormContext.types';

export const FeedbackFormContext = createContext<undefined | FeedbackFormContextValue>(undefined);

export const FeedbackFormContextProvider = ({ children }: FeedbackFormProps) => {
  const { company, email, linkId } = useUrl();
  const [appState, setAppState] = useAppState();
  const { isAlreadySent, updateSentList } = useSentList({ linkId });

  useLayoutEffect(() => {
    if (isAlreadySent) setAppState('ALREADY_SENT');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { watch, setValue, reset, getValues, register } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  });

  const { mutate, isLoading } = useMutation<PostResponseSuccess, PostResponseError, PostBody>(
    'feedback',
    (data) => axios.post(`${process.env.REACT_APP_API_URL}`, data),
    {
      onError: (err) => {
        const alreadySent = err.response?.data === 'Feedback duplicate';
        if (alreadySent) updateSentList();

        setAppState(alreadySent ? 'ALREADY_SENT' : 'ERROR');
      },
      onSuccess: () => {
        updateSentList();
        setAppState('SUCCESS');
      },
    },
  );

  const rateWatch = watch('rate');
  const opinionWatch = watch('opinion');
  const isAnyRatePicked = useMemo(() => rateWatch !== undefined, [rateWatch]);

  const submitForm = () => {
    //TODO: separate this
    mutate({
      ...getValues(),
      company: company ?? '',
      email: email ?? '',
      sendDate: new Date().toISOString(),
    });
  };

  return (
    <FeedbackFormContext.Provider
      value={{
        register,
        reset,
        setValue,
        submitForm,
        rateWatch,
        opinionWatch,
        isLoading,
        isAnyRatePicked,
        appState,
      }}
    >
      {children}
    </FeedbackFormContext.Provider>
  );
};
