import { Styles } from 'common/lib/material-ui/theme';
import { rateToPickedBgColor } from 'modules/rate/Rate';
import { Rate } from 'modules/rate/Rate.types';

type pickedResultStylesProps = (rate: Rate) => Styles;

export const pickedResult: pickedResultStylesProps = (rate) => ({
  mt: 2.5,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: 2,
  px: 1.75,
  py: 1.25,
  alignItems: 'center',
  bgcolor: rateToPickedBgColor(rate),

  '&:hover': {
    bgcolor: rateToPickedBgColor(rate),
  },
});

export const pickedResultRate: Styles = {
  color: 'mono.white',
  fontWeight: 700,
  fontSize: 20,
};
