import { forwardRef, ChangeEvent, ClipboardEvent } from 'react';
import { TextField } from '@mui/material';

import { AppMessages } from 'locale/i18n/messages';
import { useLocale } from 'locale/useLocale/useLocale';

import { Counter } from './Counter/Counter';
import * as styles from './OpinionField.styles';
import { OpinionFieldProps } from './OpinionField.types';

const SIGNS_LIMIT = 5000;

export const OpinionField = forwardRef<HTMLDivElement, OpinionFieldProps>(
  ({ isLoading, value, onChange, ...textFieldProps }, ref) => {
    const { formatMessage } = useLocale();

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = e.target;
      if (value.length > SIGNS_LIMIT) return;
      onChange(value);
    };

    const handleInputPaste = (e: ClipboardEvent) => {
      const pastedText = e.clipboardData.getData('Text');
      if (value.length + pastedText.length > SIGNS_LIMIT) e.preventDefault();
    };

    return (
      <>
        <TextField
          {...textFieldProps}
          placeholder={formatMessage({
            id: AppMessages['rating.feedback.placeholder'],
          })}
          disabled={isLoading}
          multiline
          rows={4}
          inputProps={{ sx: styles.textarea }}
          sx={styles.field}
          ref={ref}
          value={value}
          onChange={handleInputChange}
          onPaste={handleInputPaste}
        />
        <Counter count={value.length} limit={SIGNS_LIMIT} />
      </>
    );
  },
);
