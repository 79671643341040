import { Box, Typography } from '@mui/material';

import { AppMessages } from 'locale/i18n/messages';
import { useLocale } from 'locale/useLocale/useLocale';
import { useIsMobile } from 'common/lib/material-ui/useIsMobile';

import { Rogals } from './Rogals/Rogals';
import * as styles from './Template.styles';
import { TemplateProps } from './Template.types';

export const Template = ({ children }: TemplateProps) => {
  const isMobile = useIsMobile();
  const { formatMessage } = useLocale();

  return (
    <Box sx={styles.screenContainer}>
      <Box sx={styles.appContainer}>
        {!isMobile && <Rogals />}
        <Box sx={styles.contentContainer}>
          <Typography component="h1" sx={styles.title}>
            {formatMessage({ id: AppMessages['title'] })}
          </Typography>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
