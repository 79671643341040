import { useLocalStorage } from 'usehooks-ts';

import { UseSentListParams, AlreadySentList, ALREADY_SENT_KEY } from './useSentList.types';

export const useSentList = ({ linkId }: UseSentListParams) => {
  const [alreadySentList, setAlreadySentList] = useLocalStorage<AlreadySentList>(ALREADY_SENT_KEY, []);
  const isAlreadySent = linkId && alreadySentList.includes(linkId);

  const updateSentList = () => {
    if (linkId) {
      setAlreadySentList([...alreadySentList, linkId]);
    }
  };

  return { updateSentList, isAlreadySent };
};
