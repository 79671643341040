import { FC } from 'react';
import { Box } from '@mui/material';
import SimpleBar from 'simplebar-react';

import { useIsMobile } from 'common/lib/material-ui/useIsMobile';
import { useFeedbackContextForm } from 'app/FeedbackForm/hooks/useFeedbackContextForm';

import { ChangeRateButton } from './ChangeRateButton/ChangeRateButton';
import { MobilePickedRate } from './MobilePickedRate/MobilePickedRate';
import { OpinionField } from './OpinionField/OpinionField';
import { RateButtonsList } from './RateButtonsList/RateButtonsList';
import { RatingHelpers } from './RatingHelpers/RatingHelpers';
import { SubmitButton } from './SubmitButton/SubmitButton';
import { SummaryText } from './SummaryText/SummaryText';
import * as styles from './Survey.styles';
import { Recipients } from './Recipients/Recipients';

export const Survey: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const { submitForm, isAnyRatePicked, setValue, rateWatch, opinionWatch, reset, register } = useFeedbackContextForm();

  const isMobile = useIsMobile();

  return (
    <Box sx={styles.form} component="form">
      <Box component={SimpleBar} sx={{ ...styles.simpleBar, pb: isAnyRatePicked ? 0 : 1.5 }}>
        <Box
          sx={{
            ...styles.ratingContainer,
            width: isAnyRatePicked ? '100%' : 'min-content',
          }}
        >
          <RatingHelpers />
          {(!isAnyRatePicked || !isMobile) && (
            <RateButtonsList value={rateWatch} onChange={(rate) => setValue('rate', rate)} />
          )}
        </Box>
      </Box>
      {isAnyRatePicked && isMobile && (
        <>
          <MobilePickedRate rate={rateWatch} onClick={() => reset()} />
          <ChangeRateButton isLoading={isLoading} onClick={() => reset()} />
        </>
      )}
      {isAnyRatePicked ? (
        <>
          <SummaryText rate={rateWatch} />
          <OpinionField
            {...register('opinion')}
            isLoading={isLoading}
            value={opinionWatch || ''}
            onChange={(opinion) => setValue('opinion', opinion as string)}
          />
          <SubmitButton isLoading={isLoading} onClick={() => submitForm()} />
        </>
      ) : (
        <Recipients />
      )}
    </Box>
  );
};
