import { useContext } from 'react';

import { FeedbackFormContext } from '../context/FeedbackFormContext';

export const useFeedbackContextApi = () => {
  const context = useContext(FeedbackFormContext);
  if (context === undefined) throw new Error('useFeedbackContextApi must be used within a FeedbackFormContextProvider');

  const { isLoading, submitForm } = context;

  return {
    isLoading,
    submitForm,
  };
};
