import { Typography } from '@mui/material';

import { AppMessages } from 'locale/i18n/messages';
import { useLocale } from 'locale/useLocale/useLocale';
import { useFeedbackContextForm } from 'app/FeedbackForm/hooks/useFeedbackContextForm';

import * as styles from './RatingMessageText.styles';

export const RatingMessageText = () => {
  const { formatMessage } = useLocale();
  const { isAnyRatePicked } = useFeedbackContextForm();

  return (
    <Typography sx={styles.text(isAnyRatePicked)}>{formatMessage({ id: AppMessages['rating.message'] })}</Typography>
  );
};
