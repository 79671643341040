import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { LocaleContext } from '../localeContext/LocaleContext';

export const useLocale = () => {
  const intl = useIntl();
  const localeContext = useContext(LocaleContext);

  return {
    ...intl,
    ...localeContext,
  };
};
