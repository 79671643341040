import { Box } from '@mui/material';

import { useFeedbackContextApi } from 'app/FeedbackForm//hooks/useFeedbackContextApi';
import { useFeedbackContextForm } from 'app/FeedbackForm/hooks/useFeedbackContextForm';

import { Logo } from './Logo/Logo';
import { Survey } from './Survey/Survey';
import { HelloText } from './HelloText/HelloText';
import { RatingMessageText } from './RatingMessageText/RatingMessageText';
import * as styles from './Main.styles';

export const Main = () => {
  const { isAnyRatePicked } = useFeedbackContextForm();
  const { isLoading } = useFeedbackContextApi();

  return (
    <Box sx={styles.initViewContainer}>
      <Logo />

      {!isAnyRatePicked && <HelloText />}

      <RatingMessageText />

      <Survey isLoading={isLoading} />
    </Box>
  );
};
