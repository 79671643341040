import { FC } from 'react';
import { Button, Typography, ButtonProps } from '@mui/material';

import { Rate } from 'modules/rate/Rate.types';

import * as styles from './MobilePickedRate.styles';

export const MobilePickedRate: FC<ButtonProps & { rate: Rate }> = ({ rate, ...buttonProps }) => (
  <Button data-testid="pick-result" sx={styles.pickedResult(rate)} {...buttonProps}>
    <Typography sx={styles.pickedResultRate}>{rate}</Typography>
    <img src="./assets/svg/checkmark.svg" alt="checkmark" />
  </Button>
);
