import { Styles } from 'common/lib/material-ui/theme';

export const container: Styles = {
  display: 'flex',
  mt: { mobile: 5, desktop: 5.25 },
  px: { desktop: 5 },
  alignItems: 'center',
  flexDirection: 'column',
};

export const hello: Styles = {
  fontSize: 24,
  lineHeight: 1.3,
  fontWeight: 700,
  textAlign: 'center',
};

export const feedbackrequest: Styles = {
  mt: { mobile: 2, desktop: 2.25 },
  fontSize: { mobile: 14, desktop: 16 },
  lineHeight: 1.7,
  fontWeight: 500,
  textAlign: 'center',
  color: 'text.secondary',
};
