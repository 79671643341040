import { FC } from 'react';
import { Button, ButtonProps, Box } from '@mui/material';

import { AppMessages } from 'locale/i18n/messages';
import { useLocale } from 'locale/useLocale/useLocale';

import * as styles from './ChangeRateButton.styles';

export const ChangeRateButton: FC<
  ButtonProps & {
    isLoading: boolean;
  }
> = ({ isLoading, ...buttonProps }) => {
  const { formatMessage } = useLocale();

  return (
    <Box sx={styles.container}>
      <Button variant="text" disabled={isLoading} sx={styles.button} {...buttonProps}>
        {formatMessage({ id: AppMessages['rating.change'] })}
      </Button>
    </Box>
  );
};
