import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '../../../common/lib/material-ui/ThemeProvider';
import { LocaleContextController } from '../../../locale/localeContextController/LocaleContextController';

import { AppContextProps } from './AppContext.types';

export const AppContextProvider = ({ children }: AppContextProps) => (
  <QueryClientProvider client={new QueryClient()}>
    <LocaleContextController>
      <BrowserRouter>
        <ThemeProvider>{children}</ThemeProvider>
      </BrowserRouter>
    </LocaleContextController>
  </QueryClientProvider>
);
