import { Box, Typography } from '@mui/material';

import { useLocale } from 'locale/useLocale/useLocale';
import { AppMessages } from 'locale/i18n/messages';

import * as styles from './Recipients.styles';
import { RecipientCard } from './RecipientCard/RecipientCard';
import { RecipientCardProps } from './RecipientCard/RecipientCard.types';

const recepients: RecipientCardProps[] = [
  {
    img: './assets/images/MateuszKubiczek.png',
    name: 'Mateusz Kubiczek',
    position: 'CEO',
    variant: 'blue',
  },
  {
    img: './assets/images/MarekGajda.png',
    name: 'Marek Gajda',
    position: 'CTO',
    variant: 'green',
  },
  {
    img: './assets/images/Gerbert.png',
    name: 'Gerbert Oude\xa0Velthuis',
    position: 'Customer\xa0Success Director',
    variant: 'baige',
  },
  {
    img: './assets/images/LidiaLoniewska.png',
    name: 'Lidia Łoniewska',
    position: 'HR Director',
    variant: 'creamy',
  },
];

export const Recipients = () => {
  const { formatMessage } = useLocale();

  return (
    <Box sx={styles.recipientsContainer}>
      <Typography sx={styles.heardText}>{formatMessage({ id: AppMessages['initial.heard'] })}</Typography>
      <Typography sx={styles.reviewText}>{formatMessage({ id: AppMessages['initial.recipients'] })}</Typography>
      <Box sx={styles.cardsContainer}>
        {recepients.map(({ img, name, position, variant }) => (
          <RecipientCard key={name} variant={variant} img={img} name={name} position={position} />
        ))}
      </Box>
    </Box>
  );
};
