import { Styles } from 'common/lib/material-ui/theme';

export const rogal: Styles = {
  transition: 'all 5s linear',
  zIndex: 1,
  position: 'absolute',
};

export const blur: Styles = {
  zIndex: 2,
  position: 'absolute',
  width: '100%',
  height: '100%',
  backdropFilter: 'blur(15px)',
  borderRadius: 5,
};
