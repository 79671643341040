import { FC } from 'react';

import { Template } from './components/template/Template';
import { Main } from './components/views/main/Main';
import { AlreadySentResponse } from './components/views/response/AlreadySentResponse/AlreadySentResponse';
import { ErrorResponse } from './components/views/response/ErrorResponse/ErrorResponse';
import { SuccessResponse } from './components/views/response/SuccessResponse/SuccessResponse';
import { FeedbackFormContextProvider } from './context/FeedbackFormContext';
import { useFeedbackContextAppState } from './hooks/useFeedbackContextAppState';

const FeedbackFormFC: FC = () => {
  const { appState } = useFeedbackContextAppState();

  return (
    <Template>
      {appState === 'INIT' && <Main />}
      {appState === 'ALREADY_SENT' && <AlreadySentResponse />}
      {appState === 'ERROR' && <ErrorResponse />}
      {appState === 'SUCCESS' && <SuccessResponse />}
    </Template>
  );
};

export const FeedbackForm: FC = () => (
  <FeedbackFormContextProvider>
    <FeedbackFormFC />
  </FeedbackFormContextProvider>
);
