import { Styles } from 'common/lib/material-ui/theme';

import 'simplebar/dist/simplebar.min.css';

export const ratingContainer: Styles = {
  display: 'flex',
  mt: { desktop: 3.5 },
  mx: 'auto',
  px: 3.75,
  flexDirection: { mobile: 'column', desktop: 'column-reverse' },
};

export const form: Styles = {
  width: '100%',
};

export const simpleBar: Styles = {
  mx: -3.75,

  '.simplebar-track': {
    height: 12,
    margin: (theme) => `0 ${theme.spacing(3.75)}`,
    backgroundColor: 'green.main',
    borderRadius: 12 / 8,
  },

  '.simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    top: 0,
    height: '100%',
  },

  '.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before': {
    left: 0,
    right: 0,
    opacity: 1,
    backgroundColor: 'green.dark',
  },
};
