import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { useUrl } from 'common/hooks/useUrl';
import { AppMessages } from 'locale/i18n/messages';
import { useLocale } from 'locale/useLocale/useLocale';

import * as styles from './HelloText.styles';

export const HelloText: FC = () => {
  const { name } = useUrl();

  const { formatMessage } = useLocale();
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.hello}>{formatMessage({ id: AppMessages['initial.hello'] }, { name: name })}</Typography>
      <Typography sx={styles.feedbackrequest}>
        {formatMessage({ id: AppMessages['initial.feedbackRequest'] })}
      </Typography>
    </Box>
  );
};
