import { useSearchParams } from 'react-router-dom';

export const useUrl = () => {
  const [searchParams] = useSearchParams();

  return {
    name: searchParams.get('name'),
    email: searchParams.get('email'),
    company: searchParams.get('company'),
    linkId: searchParams.get('link_id'),
  };
};
