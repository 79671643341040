import { Rate } from './Rate.types';

export const RATES_LIST = [...Array(11).keys()].reverse() as Rate[];

export const rateToButtonBgColor = (rate: Rate) => (rate >= 9 ? 'good.main' : rate >= 7 ? 'medium.main' : 'bad.main');

export const rateToButtonBorderColor = (rate: Rate) =>
  rate >= 9 ? 'good.dark' : rate >= 7 ? 'medium.dark' : 'bad.dark';

export const rateToPickedBgColor = (rate: Rate) =>
  rate >= 9 ? 'good.light' : rate >= 7 ? 'medium.light' : 'bad.light';

// TODO: test
export const ratingButtonBackgroundColor = (
  isAnyRatePicked: boolean,
  isThisRatePicked: boolean,
  isMobile: boolean,
  value: Rate,
) => {
  if (isMobile) return rateToButtonBgColor(value);
  if (!isAnyRatePicked) return rateToButtonBgColor(value);
  if (isThisRatePicked) return rateToPickedBgColor(value);
  return 'mono.white';
};

export const ratingButtonFontColor = (isMobile: boolean, isThisRatePicked: boolean) =>
  isMobile || !isThisRatePicked ? 'mono.dark' : 'mono.white';

export const rateToText = (rate: Rate) =>
  ((
    {
      0: 'rating.low',
      1: 'rating.low',
      2: 'rating.low',
      3: 'rating.low',
      4: 'rating.low',
      5: 'rating.low',
      6: 'rating.low',
      7: 'rating.medium',
      8: 'rating.medium',
      9: 'rating.awesome',
      10: 'rating.awesome',
    } as const
  )[rate]);
