import { Box, Typography, Avatar } from '@mui/material';

import * as styles from './RecipientCard.styles';
import { RecipientCardProps } from './RecipientCard.types';

export const RecipientCard = ({ img, name, position, variant }: RecipientCardProps) => {
  return (
    <Box sx={styles.cardContainer(variant)}>
      <Avatar sx={styles.avatar} alt={name} src={img} />
      <Typography sx={styles.nameText}>{name}</Typography>
      <Typography sx={styles.positionText}>{position}</Typography>
    </Box>
  );
};
