import { createTheme, PaletteColor, PaletteColorOptions, SxProps } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    mono: { white: string; dark: string };
    optional: Pick<PaletteColor, 'main' | 'dark'>;
    line: string;
    bad: Omit<PaletteColor, 'contrastText'>;
    medium: Omit<PaletteColor, 'contrastText'>;
    good: Omit<PaletteColor, 'contrastText'>;
    blue: Pick<PaletteColor, 'main'>;
    green: Pick<PaletteColor, 'main' | 'dark'>;
    baige: Pick<PaletteColor, 'main'>;
    creamy: Pick<PaletteColor, 'main'>;
  }

  interface PaletteOptions {
    mono: { white: string; dark: string };
    optional: PaletteColorOptions;
    line: string;
    bad: PaletteColorOptions;
    medium: PaletteColorOptions;
    good: PaletteColorOptions;
    blue: PaletteColorOptions;
    green: PaletteColorOptions;
    baige: PaletteColorOptions;
    creamy: PaletteColorOptions;
  }

  interface TypeText {
    tertiary: string;
  }

  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    desktop: true;
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: '"Inter","Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: { main: 'hsl(236, 100%, 45%)' },
    mono: { white: 'hsl(0, 0%, 100%)', dark: 'hsl(223, 9%, 15%)' },
    optional: { main: 'hsl(222, 29%, 75%)', dark: 'hsl(222, 29%, 45%)' },
    text: { primary: 'hsl(223, 9%, 15%)', secondary: 'hsl(223, 7%, 50%)', tertiary: '#6A6F7A' },
    line: 'hsl(222, 29%, 75%)',
    bad: {
      main: 'hsl(11, 100%, 89%)',
      dark: 'hsl(11, 77%, 78%)',
      light: 'hsl(11, 79%, 62%)',
    },
    medium: {
      main: 'hsl(43, 98%, 80%)',
      dark: 'hsl(43, 64%, 62%)',
      light: 'hsl(43, 99%, 59%)',
    },
    good: {
      main: 'hsl(80, 81%, 90%)',
      dark: 'hsl(80, 36%, 72%)',
      light: 'hsl(80, 61%, 61%)',
    },
    blue: { main: '#E1F6FF' },
    green: { main: '#ECF7F6', dark: '#649CB4' },
    baige: { main: '#F8F8EB' },
    creamy: { main: '#FFF8E4' },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: '-0.03em',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: { color: 'hsl(236, 100%, 45%)' },
        contained: {
          color: 'hsl(0, 0%, 100%)',
        },
        text: {
          ':hover': { backgroundColor: 'unset' },
        },
        root: {
          color: 'hsl(223, 9%, 15%)',
          fontSize: 18,
          fontWeight: 600,
          textTransform: 'unset',
          borderRadius: 9999,
          width: '100%',
          padding: 9,
          minWidth: 'unset',
          boxShadow: 'unset',
          ':hover': { boxShadow: 'unset' },
        },
      },
    },
  },
  breakpoints: { values: { mobile: 0, desktop: 764 } },
});

export type AppTheme = typeof theme;
export type Styles = SxProps<AppTheme>;
