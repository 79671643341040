import { Styles } from 'common/lib/material-ui/theme';

import { Variant } from './RecipientCard.types';

const colors: Record<Variant, string> = {
  blue: 'blue.main',
  green: 'green.main',
  baige: 'baige.main',
  creamy: 'creamy.main',
};

export const cardContainer = (variant: Variant): Styles => ({
  pt: 3.25,
  pb: 3.75,
  px: 3.75,
  width: 150,
  height: 200,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: colors[variant],
  textAlign: 'center',
  borderRadius: 2.5,
});

export const avatar: Styles = {
  border: (theme) => `4px solid ${theme.palette.mono.white}`,
  width: 68,
  height: 68,
};

export const nameText: Styles = {
  mt: 1.5,
  fontSize: 16,
  fontWeight: 700,
  lineHeight: 1.3,
};

export const positionText: Styles = {
  mt: 0.25,
  fontSize: 12,
  fontWeight: 500,
  lineHeight: 1.7,
  color: 'text.tertiary',
};
