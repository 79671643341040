import { Styles } from 'common/lib/material-ui/theme';

export const textarea: Styles = {
  fontSize: 14,
  '::placeholder': {
    color: 'optional.dark',
  },
};

export const field: Styles = {
  border: 1,
  borderColor: 'line',
  borderRadius: 2,
  mt: { mobile: 3, desktop: 2 },
  width: '100%',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
};
